/* eslint-disable */
import * as Types from '../types.gen'

import { gql } from '@apollo/client/index.js'
import { ArtistDetailsFragmentDoc } from './artistDetails.fragment.gen'
import {
  ArtistEventInvitationsFragmentDoc,
  VenueEventInvitationsFragmentDoc,
  PromoterEventInvitationsFragmentDoc,
} from './eventInvitations.fragment.gen'
import { VenueDetailsFragmentDoc } from './venueDetails.fragment.gen'
import { PromoterDetailsFragmentDoc } from './promoterDetails.fragment.gen'
export type UserDetailsFragment = {
  __typename?: 'User'
  id: string
  name: string
  picture?: string | null
  slug: string
  email: string
  csrfToken?: string | null
  city: { __typename?: 'City'; id: number; name: string }
  selectedProfile?:
    | {
        __typename: 'Artist'
        id: string
        name: string
        description: string
        picture?: string | null
        slug: string
        genres: Array<{ __typename?: 'Genre'; id: number; name: string }>
        links: Array<{ __typename?: 'Link'; name: string; url: string }>
        eventInvitations: Array<{
          __typename?: 'EventInvitation'
          eventId: string
          eventSlug: string
          token: string
          status: Types.EventInvitationStatus
          senderProfile:
            | {
                __typename?: 'Artist'
                id: string
                name: string
                slug: string
                picture?: string | null
              }
            | {
                __typename?: 'Promoter'
                id: string
                name: string
                slug: string
                picture?: string | null
              }
            | { __typename?: 'User' }
            | {
                __typename?: 'Venue'
                id: string
                name: string
                slug: string
                picture?: string | null
              }
        }>
      }
    | {
        __typename: 'Promoter'
        id: string
        name: string
        description: string
        picture?: string | null
        slug: string
        links: Array<{ __typename?: 'Link'; name: string; url: string }>
        eventInvitations: Array<{
          __typename?: 'EventInvitation'
          eventId: string
          eventSlug: string
          token: string
          status: Types.EventInvitationStatus
          senderProfile:
            | {
                __typename?: 'Artist'
                id: string
                name: string
                slug: string
                picture?: string | null
              }
            | {
                __typename?: 'Promoter'
                id: string
                name: string
                slug: string
                picture?: string | null
              }
            | { __typename?: 'User' }
            | {
                __typename?: 'Venue'
                id: string
                name: string
                slug: string
                picture?: string | null
              }
        }>
      }
    | {
        __typename: 'User'
        id: string
        name: string
        slug: string
        picture?: string | null
      }
    | {
        __typename: 'Venue'
        id: string
        name: string
        description: string
        picture?: string | null
        address: string
        slug: string
        audienceCapacity?: number | null
        ageRestriction?: Types.AgeRestriction | null
        city: { __typename?: 'City'; id: number; name: string }
        links: Array<{ __typename?: 'Link'; name: string; url: string }>
        eventInvitations: Array<{
          __typename?: 'EventInvitation'
          eventId: string
          eventSlug: string
          token: string
          status: Types.EventInvitationStatus
          senderProfile:
            | {
                __typename?: 'Artist'
                id: string
                name: string
                slug: string
                picture?: string | null
              }
            | {
                __typename?: 'Promoter'
                id: string
                name: string
                slug: string
                picture?: string | null
              }
            | { __typename?: 'User' }
            | {
                __typename?: 'Venue'
                id: string
                name: string
                slug: string
                picture?: string | null
              }
        }>
      }
    | null
  profiles: Array<
    | {
        __typename: 'Artist'
        id: string
        name: string
        description: string
        picture?: string | null
        slug: string
        genres: Array<{ __typename?: 'Genre'; id: number; name: string }>
        links: Array<{ __typename?: 'Link'; name: string; url: string }>
      }
    | {
        __typename: 'Promoter'
        id: string
        name: string
        description: string
        picture?: string | null
        slug: string
        links: Array<{ __typename?: 'Link'; name: string; url: string }>
      }
    | {
        __typename: 'User'
        id: string
        name: string
        slug: string
        picture?: string | null
      }
    | {
        __typename: 'Venue'
        id: string
        name: string
        description: string
        picture?: string | null
        address: string
        slug: string
        audienceCapacity?: number | null
        ageRestriction?: Types.AgeRestriction | null
        city: { __typename?: 'City'; id: number; name: string }
        links: Array<{ __typename?: 'Link'; name: string; url: string }>
      }
  >
}

export const UserDetailsFragmentDoc = gql`
  fragment UserDetails on User {
    id
    name
    picture
    slug
    email
    csrfToken
    city {
      id
      name
    }
    selectedProfile {
      ... on Artist {
        ...ArtistDetails
        ...ArtistEventInvitations
      }
      ... on Venue {
        ...VenueDetails
        ...VenueEventInvitations
      }
      ... on Promoter {
        ...PromoterDetails
        ...PromoterEventInvitations
      }
      ... on User {
        __typename
        id
        name
        slug
        picture
      }
    }
    profiles {
      ... on Artist {
        ...ArtistDetails
      }
      ... on Venue {
        ...VenueDetails
      }
      ... on Promoter {
        ...PromoterDetails
      }
      ... on User {
        __typename
        id
        name
        slug
        picture
      }
    }
  }
  ${ArtistDetailsFragmentDoc}
  ${ArtistEventInvitationsFragmentDoc}
  ${VenueDetailsFragmentDoc}
  ${VenueEventInvitationsFragmentDoc}
  ${PromoterDetailsFragmentDoc}
  ${PromoterEventInvitationsFragmentDoc}
`
