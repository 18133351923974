/* eslint-disable */
import * as Types from '../../types.gen'

import { gql } from '@apollo/client/index.js'
import { UserDetailsFragmentDoc } from '../../fragments/userDetails.fragment.gen'
import * as Apollo from '@apollo/client/index.js'
const defaultOptions = {} as const
export type CurrentUserQueryVariables = Types.Exact<{ [key: string]: never }>

export type CurrentUserQuery = {
  __typename?: 'Query'
  currentUser?: {
    __typename?: 'User'
    id: string
    name: string
    picture?: string | null
    slug: string
    email: string
    csrfToken?: string | null
    city: { __typename?: 'City'; id: number; name: string }
    selectedProfile?:
      | {
          __typename: 'Artist'
          id: string
          name: string
          description: string
          picture?: string | null
          slug: string
          genres: Array<{ __typename?: 'Genre'; id: number; name: string }>
          links: Array<{ __typename?: 'Link'; name: string; url: string }>
          eventInvitations: Array<{
            __typename?: 'EventInvitation'
            eventId: string
            eventSlug: string
            token: string
            status: Types.EventInvitationStatus
            senderProfile:
              | {
                  __typename?: 'Artist'
                  id: string
                  name: string
                  slug: string
                  picture?: string | null
                }
              | {
                  __typename?: 'Promoter'
                  id: string
                  name: string
                  slug: string
                  picture?: string | null
                }
              | { __typename?: 'User' }
              | {
                  __typename?: 'Venue'
                  id: string
                  name: string
                  slug: string
                  picture?: string | null
                }
          }>
        }
      | {
          __typename: 'Promoter'
          id: string
          name: string
          description: string
          picture?: string | null
          slug: string
          links: Array<{ __typename?: 'Link'; name: string; url: string }>
          eventInvitations: Array<{
            __typename?: 'EventInvitation'
            eventId: string
            eventSlug: string
            token: string
            status: Types.EventInvitationStatus
            senderProfile:
              | {
                  __typename?: 'Artist'
                  id: string
                  name: string
                  slug: string
                  picture?: string | null
                }
              | {
                  __typename?: 'Promoter'
                  id: string
                  name: string
                  slug: string
                  picture?: string | null
                }
              | { __typename?: 'User' }
              | {
                  __typename?: 'Venue'
                  id: string
                  name: string
                  slug: string
                  picture?: string | null
                }
          }>
        }
      | {
          __typename: 'User'
          id: string
          name: string
          slug: string
          picture?: string | null
        }
      | {
          __typename: 'Venue'
          id: string
          name: string
          description: string
          picture?: string | null
          address: string
          slug: string
          audienceCapacity?: number | null
          ageRestriction?: Types.AgeRestriction | null
          city: { __typename?: 'City'; id: number; name: string }
          links: Array<{ __typename?: 'Link'; name: string; url: string }>
          eventInvitations: Array<{
            __typename?: 'EventInvitation'
            eventId: string
            eventSlug: string
            token: string
            status: Types.EventInvitationStatus
            senderProfile:
              | {
                  __typename?: 'Artist'
                  id: string
                  name: string
                  slug: string
                  picture?: string | null
                }
              | {
                  __typename?: 'Promoter'
                  id: string
                  name: string
                  slug: string
                  picture?: string | null
                }
              | { __typename?: 'User' }
              | {
                  __typename?: 'Venue'
                  id: string
                  name: string
                  slug: string
                  picture?: string | null
                }
          }>
        }
      | null
    profiles: Array<
      | {
          __typename: 'Artist'
          id: string
          name: string
          description: string
          picture?: string | null
          slug: string
          genres: Array<{ __typename?: 'Genre'; id: number; name: string }>
          links: Array<{ __typename?: 'Link'; name: string; url: string }>
        }
      | {
          __typename: 'Promoter'
          id: string
          name: string
          description: string
          picture?: string | null
          slug: string
          links: Array<{ __typename?: 'Link'; name: string; url: string }>
        }
      | {
          __typename: 'User'
          id: string
          name: string
          slug: string
          picture?: string | null
        }
      | {
          __typename: 'Venue'
          id: string
          name: string
          description: string
          picture?: string | null
          address: string
          slug: string
          audienceCapacity?: number | null
          ageRestriction?: Types.AgeRestriction | null
          city: { __typename?: 'City'; id: number; name: string }
          links: Array<{ __typename?: 'Link'; name: string; url: string }>
        }
    >
  } | null
}

export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      ...UserDetails
    }
  }
  ${UserDetailsFragmentDoc}
`

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options,
  )
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options,
  )
}
export function useCurrentUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    options,
  )
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>
export type CurrentUserSuspenseQueryHookResult = ReturnType<
  typeof useCurrentUserSuspenseQuery
>
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>
