/* eslint-disable */
import * as Types from '../../types.gen'

import { gql } from '@apollo/client/index.js'
import { CityDetailsFragmentDoc } from '../../fragments/cityDetails.fragment.gen'
import * as Apollo from '@apollo/client/index.js'
const defaultOptions = {} as const
export type GetCitiesQueryVariables = Types.Exact<{ [key: string]: never }>

export type GetCitiesQuery = {
  __typename?: 'Query'
  getCities: Array<{ __typename?: 'City'; id: number; name: string }>
}

export const GetCitiesDocument = gql`
  query getCities {
    getCities {
      ...CityDetails
    }
  }
  ${CityDetailsFragmentDoc}
`

/**
 * __useGetCitiesQuery__
 *
 * To run a query within a React component, call `useGetCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCitiesQuery,
    GetCitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCitiesQuery, GetCitiesQueryVariables>(
    GetCitiesDocument,
    options,
  )
}
export function useGetCitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCitiesQuery,
    GetCitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCitiesQuery, GetCitiesQueryVariables>(
    GetCitiesDocument,
    options,
  )
}
export function useGetCitiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCitiesQuery,
    GetCitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetCitiesQuery, GetCitiesQueryVariables>(
    GetCitiesDocument,
    options,
  )
}
export type GetCitiesQueryHookResult = ReturnType<typeof useGetCitiesQuery>
export type GetCitiesLazyQueryHookResult = ReturnType<
  typeof useGetCitiesLazyQuery
>
export type GetCitiesSuspenseQueryHookResult = ReturnType<
  typeof useGetCitiesSuspenseQuery
>
export type GetCitiesQueryResult = Apollo.QueryResult<
  GetCitiesQuery,
  GetCitiesQueryVariables
>
