/* eslint-disable */
import * as Types from '../types.gen'

import { gql } from '@apollo/client/index.js'
import { CityDetailsFragmentDoc } from './cityDetails.fragment.gen'
import { LinkDetailsFragmentDoc } from './linkDetails.fragment.gen'
export type VenueDetailsFragment = {
  __typename: 'Venue'
  id: string
  name: string
  description: string
  picture?: string | null
  address: string
  slug: string
  audienceCapacity?: number | null
  ageRestriction?: Types.AgeRestriction | null
  city: { __typename?: 'City'; id: number; name: string }
  links: Array<{ __typename?: 'Link'; name: string; url: string }>
}

export type VenueSearchDetailsFragment = {
  __typename?: 'Venue'
  id: string
  name: string
  picture?: string | null
  slug: string
  audienceCapacity?: number | null
  city: { __typename?: 'City'; id: number; name: string }
}

export const VenueDetailsFragmentDoc = gql`
  fragment VenueDetails on Venue {
    __typename
    id
    name
    description
    picture
    address
    slug
    audienceCapacity
    ageRestriction
    city {
      ...CityDetails
    }
    links {
      ...LinkDetails
    }
  }
  ${CityDetailsFragmentDoc}
  ${LinkDetailsFragmentDoc}
`
export const VenueSearchDetailsFragmentDoc = gql`
  fragment VenueSearchDetails on Venue {
    id
    name
    picture
    slug
    audienceCapacity
    city {
      ...CityDetails
    }
  }
  ${CityDetailsFragmentDoc}
`
