import ReactSelect, {
  GroupBase,
  Props,
  Theme as ReactSelectTheme,
} from 'react-select'

import Creatable, { CreatableProps } from 'react-select/creatable'

import { useTheme } from '@emotion/react'
import chroma from 'chroma-js'

import { Theme } from '../../../style/themes/theme.types'

import styles from './Select.styles'
import { ClientOnly } from '../../../utilities/ClientOnly'

export interface OptionValue<V> {
  label: string
  value: V
}

function useCustomTheme() {
  const theme: Theme = useTheme()

  const customTheme = (existingTheme: ReactSelectTheme): ReactSelectTheme => ({
    ...existingTheme,

    borderRadius: 8,
    colors: {
      primary: theme.color.primary,
      primary75: chroma(theme.fontColor.plain.secondary).luminance(1).hex(),
      primary50: chroma(theme.fontColor.plain.secondary).luminance(0.5).hex(),
      primary25: chroma(theme.fontColor.plain.secondary).luminance(0.05).hex(),
      danger: theme.color.alert,
      dangerLight: chroma(theme.color.alert).brighten().hex(),
      neutral0: theme.backgroundColor.primary,
      neutral5: chroma(theme.backgroundColor.primary).luminance(0.05).hex(),
      neutral10: chroma(theme.backgroundColor.primary).luminance(0.1).hex(),
      neutral20: chroma(theme.backgroundColor.primary).luminance(0.2).hex(),
      neutral30: chroma(theme.backgroundColor.primary).luminance(0.3).hex(),
      neutral40: chroma(theme.backgroundColor.primary).luminance(0.4).hex(),
      neutral50: chroma(theme.backgroundColor.primary).luminance(0.5).hex(),
      neutral60: chroma(theme.backgroundColor.primary).luminance(0.6).hex(),
      neutral70: chroma(theme.backgroundColor.primary).luminance(0.7).hex(),
      neutral80: chroma(theme.backgroundColor.primary).luminance(0.8).hex(),
      neutral90: chroma(theme.backgroundColor.primary).luminance(0.9).hex(),
    },
  })

  return { customTheme, theme }
}

function Select<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: Props<Option, IsMulti, Group>) {
  const { customTheme, theme } = useCustomTheme()

  return (
    // <ClientOnly>
    <ReactSelect
      theme={customTheme}
      styles={styles<Option, IsMulti, Group>(theme)}
      {...props}
    />
    // </ClientOnly>
  )
}

export function CreatableSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: CreatableProps<Option, IsMulti, Group>) {
  const { customTheme, theme } = useCustomTheme()

  return (
    <ClientOnly>
      <Creatable
        theme={customTheme}
        styles={styles<Option, IsMulti, Group>(theme)}
        {...props}
      />
    </ClientOnly>
  )
}

export default Select
