/* eslint-disable */
import * as Types from '../types.gen'

import { gql } from '@apollo/client/index.js'
export type ArtistEventInvitationsFragment = {
  __typename?: 'Artist'
  eventInvitations: Array<{
    __typename?: 'EventInvitation'
    eventId: string
    eventSlug: string
    token: string
    status: Types.EventInvitationStatus
    senderProfile:
      | {
          __typename?: 'Artist'
          id: string
          name: string
          slug: string
          picture?: string | null
        }
      | {
          __typename?: 'Promoter'
          id: string
          name: string
          slug: string
          picture?: string | null
        }
      | { __typename?: 'User' }
      | {
          __typename?: 'Venue'
          id: string
          name: string
          slug: string
          picture?: string | null
        }
  }>
}

export type VenueEventInvitationsFragment = {
  __typename?: 'Venue'
  eventInvitations: Array<{
    __typename?: 'EventInvitation'
    eventId: string
    eventSlug: string
    token: string
    status: Types.EventInvitationStatus
    senderProfile:
      | {
          __typename?: 'Artist'
          id: string
          name: string
          slug: string
          picture?: string | null
        }
      | {
          __typename?: 'Promoter'
          id: string
          name: string
          slug: string
          picture?: string | null
        }
      | { __typename?: 'User' }
      | {
          __typename?: 'Venue'
          id: string
          name: string
          slug: string
          picture?: string | null
        }
  }>
}

export type PromoterEventInvitationsFragment = {
  __typename?: 'Promoter'
  eventInvitations: Array<{
    __typename?: 'EventInvitation'
    eventId: string
    eventSlug: string
    token: string
    status: Types.EventInvitationStatus
    senderProfile:
      | {
          __typename?: 'Artist'
          id: string
          name: string
          slug: string
          picture?: string | null
        }
      | {
          __typename?: 'Promoter'
          id: string
          name: string
          slug: string
          picture?: string | null
        }
      | { __typename?: 'User' }
      | {
          __typename?: 'Venue'
          id: string
          name: string
          slug: string
          picture?: string | null
        }
  }>
}

export const ArtistEventInvitationsFragmentDoc = gql`
  fragment ArtistEventInvitations on Artist {
    eventInvitations {
      eventId
      eventSlug
      token
      status
      senderProfile {
        ... on Artist {
          id
          name
          slug
          picture
        }
        ... on Venue {
          id
          name
          slug
          picture
        }
        ... on Promoter {
          id
          name
          slug
          picture
        }
      }
    }
  }
`
export const VenueEventInvitationsFragmentDoc = gql`
  fragment VenueEventInvitations on Venue {
    eventInvitations {
      eventId
      eventSlug
      token
      status
      senderProfile {
        ... on Artist {
          id
          name
          slug
          picture
        }
        ... on Venue {
          id
          name
          slug
          picture
        }
        ... on Promoter {
          id
          name
          slug
          picture
        }
      }
    }
  }
`
export const PromoterEventInvitationsFragmentDoc = gql`
  fragment PromoterEventInvitations on Promoter {
    eventInvitations {
      eventId
      eventSlug
      token
      status
      senderProfile {
        ... on Artist {
          id
          name
          slug
          picture
        }
        ... on Venue {
          id
          name
          slug
          picture
        }
        ... on Promoter {
          id
          name
          slug
          picture
        }
      }
    }
  }
`
