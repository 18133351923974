/* eslint-disable */
import * as Types from '../types.gen'

import { gql } from '@apollo/client/index.js'
import { GenreDetailsFragmentDoc } from './genreDetails.fragment.gen'
import { LinkDetailsFragmentDoc } from './linkDetails.fragment.gen'
export type ArtistDetailsFragment = {
  __typename: 'Artist'
  id: string
  name: string
  description: string
  picture?: string | null
  slug: string
  genres: Array<{ __typename?: 'Genre'; id: number; name: string }>
  links: Array<{ __typename?: 'Link'; name: string; url: string }>
}

export type ArtistSearchDetailsFragment = {
  __typename: 'Artist'
  id: string
  name: string
  slug: string
  genres: Array<{ __typename?: 'Genre'; id: number; name: string }>
}

export const ArtistDetailsFragmentDoc = gql`
  fragment ArtistDetails on Artist {
    __typename
    id
    name
    description
    picture
    slug
    genres {
      ...GenreDetails
    }
    links {
      ...LinkDetails
    }
  }
  ${GenreDetailsFragmentDoc}
  ${LinkDetailsFragmentDoc}
`
export const ArtistSearchDetailsFragmentDoc = gql`
  fragment ArtistSearchDetails on Artist {
    __typename
    id
    name
    slug
    genres {
      ...GenreDetails
    }
  }
  ${GenreDetailsFragmentDoc}
`
