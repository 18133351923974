import { StylesConfig, GroupBase } from 'react-select'
import { Theme } from '../../../style/themes/theme.types'

const styles = <
  Option,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  theme: Theme,
): StylesConfig<Option, IsMulti, Group> => ({
  container: (baseStyle) => ({
    ...baseStyle,
    width: '100%',
    fontFamily: theme.font.primary,
  }),
  control: (baseStyle) => ({
    ...baseStyle,
    border: `2px solid ${theme.borderColor.secondary}`,
  }),
  input: (baseStyle) => ({
    ...baseStyle,
    margin: 0,
  }),
  valueContainer: (baseStyle) => ({
    ...baseStyle,
    fontSize: theme.fontSize.M,
  }),
  menu: (baseStyle) => ({
    ...baseStyle,
    overflow: 'hidden',
    border: `2px solid ${theme.borderColor.secondary}`,
  }),
  menuList: (baseStyle) => ({
    ...baseStyle,
    padding: 0,
  }),
  option: (baseStyle) => ({
    ...baseStyle,
    textAlign: 'center',
    borderBottom: `1px solid ${theme.borderColor.tertiary}`,
  }),
  placeholder: (baseStyle) => ({
    ...baseStyle,
    color: theme.fontColor.plain.tertiary,
    fontSize: theme.fontSize.M,
  }),
})

export default styles
