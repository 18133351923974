/* eslint-disable */
import * as Types from '../types.gen'

import { gql } from '@apollo/client/index.js'
export type GenreDetailsFragment = {
  __typename?: 'Genre'
  id: number
  name: string
}

export const GenreDetailsFragmentDoc = gql`
  fragment GenreDetails on Genre {
    id
    name
  }
`
