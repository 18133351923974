/* eslint-disable */
import * as Types from '../types.gen'

import { gql } from '@apollo/client/index.js'
export type CityDetailsFragment = {
  __typename?: 'City'
  id: number
  name: string
}

export const CityDetailsFragmentDoc = gql`
  fragment CityDetails on City {
    id
    name
  }
`
